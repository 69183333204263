import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axiosNova';
import axiosMock from 'src/utils/axios';
import moment from 'moment';

const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');

const initialState = {
  sources: {},
  campaigns: [],
  selectedSourceAccount: {
    source: null,
    account: null,
  },
  campaignsRequest: {
    startDate: startDate,
    endDate: endDate,
  },
  loading: false,
};

export const toggleLoading = () => async (dispatch) => {
  dispatch(slice.actions.toggleLoading({}));
};

const slice = createSlice({
  name: 'ts',
  initialState,
  reducers: {
    getCampaigns(state, action) {
      const { success } = action.payload;
      if (success) {
        state.campaigns = action.payload.data;
      } else {
        state.campaigns = [];
      }
    },
    clearCampaigns(state, action) {
      const all = action.payload;
      state.campaigns = [];
      if (all) {
        state.campaignsRequest = {
          startDate: startDate,
          endDate: endDate,
        };
      }
    },
    getSources(state, action) {
      const { sources } = action.payload;
      state.sources = sources;
    },
    updateSourceAccount(state, action) {
      const data = action.payload;
      state.selectedSourceAccount = {
        ...state.selectedSourceAccount,
        ...data,
      };
    },
    updateCampaignsRequest(state, action) {
      const data = action.payload;
      state.campaignsRequest = {
        ...state.campaignsRequest,
        ...data,
      };
    },
    toggleLoading(state, action) {
      state.loading = !state.loading;
    },
  }
});

export const reducer = slice.reducer;

export const getCampaigns = (source, account, request) => async (dispatch) => {
  try {
    await dispatch(toggleLoading());
    const response = await axios.get(`/api/ts/campaigns/${source}`, {
      params: {
        ...request,
        account: account,
      }
    });

    await dispatch(toggleLoading());
    let data = response.data;
    if (!response.data.success) {
      console.error(`request error: `, response.data.message);

      return new Promise((resolve, reject) => {
        reject(data);
      });
    }

    dispatch(slice.actions.getCampaigns(data));
    return new Promise((resolve, reject) => {
      resolve(data);
    });
  } catch (err) {
    await dispatch(toggleLoading());
    console.error(err);
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
};

export const clearCampaigns = (all) => async (dispatch) => {

  dispatch(slice.actions.clearCampaigns(all));
};

export const getSources = () => async (dispatch) => {
  await dispatch(toggleLoading());
  const response = await axiosMock.get(`/api/campaigns/sources`);
  await dispatch(toggleLoading());

  dispatch(slice.actions.getSources(response.data));
};

export const updateSourceAccount = (value) => async (dispatch) => {

  dispatch(slice.actions.updateSourceAccount(value));
};

export const updateCampaignsRequest = (value) => async (dispatch) => {

  dispatch(slice.actions.updateCampaignsRequest(value));
};


export default slice;
