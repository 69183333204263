import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as financeReducer } from 'src/slices/nova/finance';
import { reducer as thriveReducer } from 'src/slices/nova/thrive';
import { reducer as tsReducer } from 'src/slices/nova/ts';
import { reducer as clientsReducer } from 'src/slices/nova/clients';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  finance: financeReducer,
  thrive: thriveReducer,
  ts: tsReducer,
  users: userReducer,
  clients: clientsReducer,
});

export default rootReducer;
