import { createSlice } from '@reduxjs/toolkit';
import axiosMock from 'src/utils/axios';

const initialState = {
  sources: {},
  selectedSourceAccount: {
    source: null,
    account: null,
  },
  loading: false,
};

export const toggleLoading = () => async (dispatch) => {
  dispatch(slice.actions.toggleLoading({}));
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getSources(state, action) {
      const { sources } = action.payload;
      state.sources = sources;
    },
    toggleLoading(state, action) {
      state.loading = !state.loading;
    },
  }
});

export const reducer = slice.reducer;

export const getSources = () => async (dispatch) => {
  await dispatch(toggleLoading());
  const response = await axiosMock.get(`/api/clients/sources`);
  await dispatch(toggleLoading());

  dispatch(slice.actions.getSources(response.data));
};

export default slice;
